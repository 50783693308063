import { BookingContainer } from "../components";

const UserBookings = () => {
  return (
    <>
      {/* <SearchContainer /> */}
      <BookingContainer />
    </>
  );
};

export default UserBookings;
