export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_VALUES = "CLEAR_VALUES";

// job actions
export const CREATE_JOB_BEGIN = "CREATE_JOB_BEGIN";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_ERROR = "CREATE_JOB_ERROR";

export const GET_JOBS_BEGIN = "GET_JOBS_BEGIN";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";

export const SET_EDIT_JOB = "SET_EDIT_JOB";

export const DELETE_JOB_BEGIN = "DELETE_JOB_BEGIN";

export const EDIT_JOB_BEGIN = "EDIT_JOB_BEGIN";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_JOB_ERROR = "EDIT_JOB_ERROR";

// booking actions
export const CREATE_BOOKING_BEGIN = "CREATE_BOOKING_BEGIN";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR";

export const GET_BOOKINGS_BEGIN = "GET_BOOKINGS_BEGIN";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";

export const SET_EDIT_BOOKING = "SET_EDIT_BOOKING";

export const DELETE_BOOKING_BEGIN = "DELETE_BOOKING_BEGIN";

export const EDIT_BOOKING_BEGIN = "EDIT_BOOKING_BEGIN";
export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";
export const EDIT_BOOKING_ERROR = "EDIT_BOOKING_ERROR";
// stats actions
export const SHOW_STATS_BEGIN = "SHOW_STATS_BEGIN";
export const SHOW_STATS_SUCCESS = "SHOW_STATS_SUCCESS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CHANGE_PAGE = "CHANGE_PAGE";
